/*
    this is a modified version of the code shown here:
    https://material-ui.com/components/snackbars/#customized-snackbars
*/
import React from "react"
import clsx from "clsx"

import { Snackbar, SnackbarContent, IconButton} from "@material-ui/core"

import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import WarningIcon from "@material-ui/icons/Warning"
import InfoIcon from "@material-ui/icons/Info"
import CloseIcon from "@material-ui/icons/Close"
import NetworkIcon from "@material-ui/icons/CloudOff"
import ShortTextIcon from "@material-ui/icons/ShortText"

import {
    red, green, blue, amber, grey
} from "@material-ui/core/colors"

import { makeStyles } from "@material-ui/core/styles"


enum StatusSnackbarTypes {
    STANDARD = "STANDARD",
    INFO = "INFO",
    SUCCESS = "SUCCESS",
    WARNING = "WARNING",
    ERROR = "ERROR",
    NETERR = "NETERR"
}


const variantIcon = {
    STANDARD: ShortTextIcon,
    SUCCESS: CheckCircleIcon,
    WARNING: WarningIcon,
    ERROR: ErrorIcon,
    INFO: InfoIcon,
    NETERR: NetworkIcon,
}

const styles = makeStyles(theme => ({
    SUCCESS: {
        backgroundColor: green[600],
    },
    ERROR: {
        backgroundColor: red[400],
    },
    NETERR: {
        backgroundColor: red[700],
    },    
    INFO: {
        backgroundColor: blue[700],
    },
    WARNING: {
        backgroundColor: amber[700],
    },
    STANDARD: {
        backgroundColor: grey[900]
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    headerMargin: {
        marginTop: theme.mixins.toolbar.minHeight
    }
}))

export type SnackMessage = {
    value: string,
    variant: StatusSnackbarTypes
}

type StatusSnackbarProps = {
    open: boolean,
    message: SnackMessage,
    onClose: () => void,
    className?: string,
    [snackbarProps:string]: any
}

const StatusSnackbar = (props: StatusSnackbarProps) => {
    const classes = styles()
    const { open, className, message, onClose, ...snackbarProps } = props;
    const Icon = variantIcon[message.variant];

    return (
        <Snackbar 
            // TODO make this adjustable
            autoHideDuration={3000}
            onClose={onClose}
            className={clsx(classes.headerMargin, className)} 
            open={open}
            {...snackbarProps}>
            <SnackbarContent
                className={classes[message.variant]}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon className={clsx(classes.icon, classes.iconVariant)} />
                        {message.value}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
}


export { StatusSnackbar, StatusSnackbarTypes }
