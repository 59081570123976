import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router"
import { BrowserRouter } from "react-router-dom"
import { createStore } from "redux"
import { Provider } from "react-redux"
import { uuid } from "uuidv4"


import { MuiThemeProvider } from "@material-ui/core/styles"
import { SuspenseScreen } from "./shared/components/Suspense"
import { MaterialFoxTheme } from "./shared/themes/reserveraccoon"
import reducer from "./redux/reducers"
import reportWebVitals from './reportWebVitals';
import { register as registerServiceWorker } from "./serviceWorkerRegistration"
import AppFrameContainer from "./AppFrame"
import AppRouter from "./AppRouter"

const HudContainer = lazy(() => import("./Hud"))
const KioskContainer = lazy(() => import("./Kiosk"))
const AirportFIDSContainer = lazy(() => import("./AirportFIDS"))

const store = createStore(reducer)

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <MuiThemeProvider theme={MaterialFoxTheme}>
                <BrowserRouter>
                    <Suspense fallback={<SuspenseScreen />}>
                        <Switch>
                            <Route exact path="/" render={(props: any) => (<AppRouter {...props}/> )} />
                            <Route exact path="/kiosk" render={(props: any) => (<AppFrameContainer component={  <KioskContainer {...props} /> } key={uuid()} />  )} />
                            <Route exact path="/hud/:netId?" render={(props: any) => (<AppFrameContainer component={  <HudContainer {...props} /> } />)} />
                            <Route exact path="/ahud/:airportId?" render={(props: any) => (<AppFrameContainer component={  <AirportFIDSContainer {...props} /> } />)} />
                        </Switch>
                    </Suspense>
                </BrowserRouter>
            </MuiThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
registerServiceWorker({
    onSuccess: () => {
        console.debug("service worker registered")
    },
    onUpdate: (registration) => {}
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

