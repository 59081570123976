// store non-credential session data

import { SessionAction, SessionState, ReduxAction} from "../base"

export const session = (state: SessionState = {}, action: ReduxAction<SessionAction, SessionState>): SessionState => {
    switch(action.type) {
        case SessionAction.SET_AIRPORT: {
            const { airportId, airportName, airportTz } = action.props
            return {
                ...state,
                airportId,
                airportName,
                airportTz
            }
        }
        case SessionAction.CLEAR: {
            return {
                airportId: null,
                airportName: null,
                airportTz: null
            }
        }
        default:
            return state
    }
}