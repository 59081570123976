/*
    This class component is responsible for parsing url parameters and routing the
    client through an internal route.

*/
import React, { Component } from "react"
import { Redirect } from "react-router-dom";

interface AppRouterProps {

    // passed in from Router HOC
    location: Record<string, any>
}

interface AppRouterState {

}

class AppRouter extends Component<AppRouterProps, AppRouterState> {

    constructor(props: AppRouterProps) {
        super(props)
        this.state = {}
    }

    render(): React.ReactElement {
        const query = new URLSearchParams(this.props.location.search)

        // order of precedance is: if airport_id is given then route to airport hud component
        // if net_id is given then route to net hud
        // else default to kiosk access page
        const airportId = query.get("airport_id")
        const netId = query.get("net_id")

        if(airportId) {
            return <Redirect to={`/ahud/${airportId}`}/>
        }
        else if(netId) {
            return <Redirect to={`/hud/${netId}`}/>
        }
        else {
            return <Redirect to="/kiosk"/>
        }

    }
}

export default AppRouter