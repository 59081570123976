export function isDevelopmentEnv() : boolean {
    return process.env.REACT_APP_DEVELOPMENT === "1"
}

export function jsDateToISODate(value: Date): string {
    // convert a js Date object to a UTC YYYY-MM-DD string
    const year = value.getUTCFullYear()
    const month = ("00" + (value.getUTCMonth() + 1)).slice(-2)
    const date = ("00" + value.getUTCDate()).slice(-2)

    return `${year}-${month}-${date}`
}

export function isEmptyString(value: string | number | null | undefined): boolean {
    // returns true if the string is null, empty, or just white space
    if(typeof value === "number") {
        value = "" + value
    }
    return (value === null || !value || 0 === value.length || !value.trim())
}

export function sleep(ms: number): Promise<unknown> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function toTitleCase(text: string): string {
    return text.replace(/\w\S*/g, function(s){return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();});
}

export function cleanCut(sentence: string, maxLength: number): string {
    // a naive approach to cutting a sentence down to size without cutting a 
    // word in half
    const BreakException = {};

    
    let result = ""
    let l = 0

    if(maxLength >= sentence.length)
        result = sentence
    else {
        const words = sentence.split(" ")
        try {
            words.forEach((word, idx) => {
                if (l + (word.length + 1) >= maxLength) {
                    result = `${words.slice(0, idx).join(" ")}...`;
                    throw BreakException;
                } 
                else {
                    l += word.length + 1;
                }
            });
        } catch (e) {
            if (e !== BreakException) throw e;
        }
    }

    return result
}

export enum ContactMethod {
    SMS = "sms",
    EMAIL = "email"
}

export enum AccountType {
    FOX_ACCOUNT = "fox",
    MERCHANT_ACCOUNT = "merchant",
    AIRPORT_ACCOUNT = "airport"
}
