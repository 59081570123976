import { FetchyGQLClient } from "../fetchyql/base";

export interface ReduxAction<T, V> {
    type: T,
    props: V
}


export enum ServiceWorkerAction {
    SW_REGISTER = "SW_REGISTER",                // initialize serviceworker creation
    SW_INIT = "SW_INIT",                        // serviceworker created
    SW_UPDATE_TIMEOUT = "SW_UPDATE_TIMEOUT",    // serviceworker didn't receive onupdate event after fixed time
    SW_UPDATE = "SW_UPDATE"                     // update available for service worker
}

export enum CredentialAction {
    SET_CREDENTIALS = "SET_CREDENTIALS",
    CLEAR_CREDENTIALS = "CLEAR_CREDENTIALS"
}

export enum GQLClientAction {
    SET_CLIENT = "SET_CLIENT",
    CLEAR_CLIENT = "CLEAR_CLIENT"
}

export enum SessionAction {
    SET_AIRPORT = "SET_AIRPORT",
    CLEAR = "CLEAR"
}

export interface CredentialState {
    userId?: string
    accessToken?: string
}

export interface GQLClientState {
    client?: FetchyGQLClient
}

export interface SessionState {
    airportName?: string,
    airportId?: string,
    airportTz?: string
}

export interface ServiceWorkerState {
    checking?: boolean,
    initialized?: boolean,
    updated?: boolean,
    registration?: any
}
